@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /*
Author       : themesoft69
Template Name: Pucu - React Portfolio Template
Version      : 1.0.1
*/

  /* Google Fonts */
  @import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Inter:wght@400;600;700;800;900&display=swap");

  /* Reset Css */
  * {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  html,
  body {
    overflow-x: hidden;
    font-family: "Inter", sans-serif;
    background: #252525;
    color: #fff;
    font-size: 15px;
  }

  /* body {
    font-family: "Inter", sans-serif;
    background: #252525;
    color: #fff;
    font-size: 15px;
  } */

  p {
    line-height: 26px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Bebas Neue", sans-serif;
  }
}

@layer components {
  .boxShadow {
    box-shadow: 5px 5px 15px #d1d9e6, -5px -5px 15px #ffffff;
  }

  .dashboard-list {
    @apply flex items-center gap-[0px] pl-[18px] text-text transition-all duration-200 hover:text-white;
  }
  .progress .break-all {
    background: #2ff093 !important;
  }
  .toaster-container {
    inset: 0 !important;
  }
  .ql-container {
    height: 300px !important;
  }
}

/* Hide scrollbar for IE, Edge add Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}

.sidebar {
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
.sidebar::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}
